import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ImagesContent from '../components/ImagesContent';
import ActivitiesListing from '../components/ActivitiesListing';
import ModularBlocks from '../components/ModularBlocks';
import Testimonials from '../components/Testimonials';

const GroupPageTemplate = ({
  data: {
    datoCmsGroup: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      bannerLink,
      introOverline,
      introHeading,
      introText,
      introLink,
      introImages,
      featuredActivitiesHeading,
      featuredActivitiesText,
      featuredActivitiesLink,
      featuredActivities,
      modularBlocks,
      testimonialsHeading,
      testimonialsText,
      testimonials,
    },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        heading={title}
        image={bannerImage}
        text={bannerText}
        link={bannerLink}
      />
      <ImagesContent
        images={introImages}
        overline={introOverline}
        heading={introHeading}
        text={introText}
        link={introLink}
        flip={true}
      />
      <hr />
      <ActivitiesListing
        overline="Group Activities"
        heading={featuredActivitiesHeading}
        text={featuredActivitiesText}
        link={featuredActivitiesLink}
        items={featuredActivities}
      />
      <hr />
      <ModularBlocks items={modularBlocks} />
      <Testimonials
        overline="Testimonials"
        heading={testimonialsHeading}
        text={testimonialsText}
        items={testimonials}
      />
    </main>
  </Layout>
);

export const GroupPageTemplateQuery = graphql`
  query GroupPageTemplateQuery($id: String!) {
    datoCmsGroup(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...SubPageBannerImageFragment
      }
      bannerText
      bannerLink {
        text
        page {
          ...LinkFragment
        }
      }
      introOverline
      introHeading
      introText
      introLink {
        text
        page {
          ...LinkFragment
        }
      }
      introImages {
        ...ImagesContentImageFragment
      }
      featuredActivitiesHeading
      featuredActivitiesText
      featuredActivitiesLink {
        text
        page {
          ...LinkFragment
        }
      }
      featuredActivities {
        ...ActivityListingItemFragment
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...ContentFeaturedPointsModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedAccommodationModularBlockFragment
        ...FeaturedStatisticsModularBlockFragment
        ...FileDownloadsModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...PackagesModularBlockFragment
      }
      testimonialsHeading
      testimonialsText
      testimonials {
        id
        quote
        author
      }
    }
  }
`;

export default GroupPageTemplate;
